import { type FC, useState, useEffect } from "react";
import { Navigate } from "react-router";

import { useAuth } from "@/hooks/auth";

const Logout: FC = () => {
  const { signOut } = useAuth();
  const [is_logged_out, setIsLoggedOut] = useState(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const abort_controller = new AbortController();
    const handleLogout = async () => {
      await signOut(abort_controller);
      setIsLoggedOut(true);
    };
    handleLogout();
    return () => {
      abort_controller.abort();
    };
  }, []);

  if (is_logged_out) {
    return <Navigate to="/login" />;
  }
  return null;
};

export default Logout;
