import { useRouteError } from "react-router";

interface RouteError {
  status?: number;
  detail?: string;
}

export function ErrorPage() {
  const error = useRouteError() as RouteError;
  return (
    <div className="flex min-h-screen items-center">
      <div className="mx-auto w-96 rounded-sm bg-slate-800 p-4 shadow-sm">
        <h1 className="text-2xl font-bold text-red-500">Error</h1>
        <p>{error.detail ? error.detail : "An unknown error occurred."}</p>
      </div>
    </div>
  );
}
