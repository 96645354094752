import {
  redirect,
  type LoaderFunction,
  type LoaderFunctionArgs,
} from "react-router";
import { marked } from "marked";
import { twMerge } from "tailwind-merge";
import clsx, { type ClassValue } from "clsx";

import { AUTH_COOKIE_NAME, taco_cookie_ctx } from "@/auth-cookie";

export const hasRole = (user, role) => {
  return (user?.roles && user.roles.includes(role)) || false;
};

export const prettyRole = (role) => {
  switch (role) {
    case "assessor":
      return "Assessor";
    case "full_access":
      return "Full access";
    case "admin":
      return "Admin";
    default:
      return "";
  }
};

export const partialObjectUpdater = (original, partial) => {
  const copy = { ...original };
  for (const key of Object.keys(partial)) {
    if (partial[key] && typeof partial[key] === "object") {
      copy[key] = partialObjectUpdater(copy[key], partial[key]);
    } else {
      copy[key] = partial[key];
    }
  }
  return copy;
};

export const copyTextToClipboard = (text) => {
  return navigator.clipboard.writeText(text).then(
    () => true,
    (err) => {
      console.error("Async: Could not copy text: ", err);
      return false;
    },
  );
};

export const scrollToBottom = (ref) => {
  ref.current.scrollIntoView({
    block: "end",
    inline: "nearest",
    behavior: "smooth",
  });
};

export const prettyDateString = (datestring) => {
  // by adding UTC, we can parse the datestring as UTC time
  const date = new Date(Date.parse(`${datestring} UTC`));
  return date.toLocaleString().replace("T", " ").split(".")[0];
};

function getExtension(file_type) {
  const parts = file_type.split("/");
  return parts[parts.length - 1];
}

export const isImage = (file_type) => {
  const ext = getExtension(file_type);
  switch (ext.toLowerCase()) {
    case "jpg":
    case "jpeg":
    case "png":
    case "webp":
      return true;
  }
  return false;
};

export const nl2br = (content) => {
  return content.split("\n").map((item, key) => {
    return (
      // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
      <span key={key}>
        {item}
        <br />
      </span>
    );
  });
};

export const renderMarkdown = (content) => {
  return { __html: marked.parse(content) };
};

export const boxclass = clsx(
  "rounded-md border border-slate-800 bg-slate-900 p-4 shadow-md",
);

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function authWrapper(loader: LoaderFunction) {
  return async (args: LoaderFunctionArgs) => {
    const { request } = args;
    const jwt = taco_cookie_ctx.get(AUTH_COOKIE_NAME);
    if (!jwt) {
      const url = new URL(request.url);
      const params = new URLSearchParams();
      params.set("to", url.pathname + url.search);
      throw redirect(`/login?${params.toString()}`);
    }
    return loader(args);
  };
}
