import type { RouteObject } from "react-router";

import { app_routes } from "@/routes/app.tsx";
import CreateUser from "@/pages/create-user.tsx";
import LoginPage from "@/pages/login.tsx";
import TokenLogin from "@/pages/token-login.tsx";

export const main_routes: RouteObject[] = [
  { path: "/login", element: <LoginPage /> },
  { path: "/token-login/:token", element: <TokenLogin /> },
  { path: "/create-user/:token", element: <CreateUser /> },
  ...app_routes,
];
