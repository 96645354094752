import { Outlet, ScrollRestoration } from "react-router";

import Navigation from "@/components/navigation";

export default function App(): JSX.Element {
  return (
    <>
      <Navigation />
      <ScrollRestoration />
      <Outlet />
    </>
  );
}
