import { redirect, type RouteObject } from "react-router";

import App from "@/app.tsx";
import Logout from "@/components/logout.tsx";
import AccountSettings from "@/pages/account-settings.tsx";
import Start, { loader as startLoader } from "@/pages/start.tsx";
import { ErrorPage } from "@/pages/error.tsx";
import Competencies, {
  loader as competenciesLoader,
} from "@/pages/competencies";
import AssignmentImport, {
  loader as assignmentImportLoader,
} from "@/pages/assignment-import.tsx";
import { assignment_routes } from "@/routes/assignment.tsx";
import { authWrapper } from "@/helpers.tsx";

export const app_routes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <Start />,
        loader: authWrapper(startLoader),
      },
      ...assignment_routes,
      {
        path: "import",
        loader: authWrapper(assignmentImportLoader),
        element: <AssignmentImport />,
      },
      {
        path: "competencies",
        loader: authWrapper(competenciesLoader),
        element: <Competencies />,
      },
      {
        path: "account-settings",
        element: <AccountSettings />,
      },
      {
        path: "logout",
        element: <Logout />,
      },
      {
        path: "*",
        element: <h1>404 Not found</h1>,
      },
    ],
    ErrorBoundary: ErrorPage,
  },
];
