import ErrorMessage from "@/components/error-message";
import { cn } from "@/helpers";

interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  register?: any;
  error?: string;
  no_margin?: boolean;
}

const Textarea = ({
  label,
  register,
  error,
  className,
  no_margin,
  ...props
}: TextareaProps) => {
  if (register && !props.id) {
    console.error("Input with 'register' must also have 'id'");
  }

  const inputclass = cn(
    "w-full",
    "flex",
    "bg-slate-900",
    "p-2",
    "rounded",
    "border-2",
    "active:border-gray-600",
    "transition-[outline-offset]",
    "duration-150",
    "ease-in-out",
    "outline-offset-0",
    "text-white",
    "placeholder:text-slate-400",
    "focus-visible:outline-offset-2",
    className,
    {
      "mb-5": !no_margin,
      "border-gray-700": !error,
      "border-red-400": error,
      "text-gray-400": props.disabled,
    },
  );

  if (label) {
    return (
      <label htmlFor={props.id} className="block w-full">
        <span className="inline-block leading-relaxed">{label}</span>
        <textarea
          name={props.id}
          className={inputclass}
          {...(register ? register(props.id) : [])}
          {...props}
        />
        <ErrorMessage message={error} />
      </label>
    );
  }
  return (
    <div>
      <textarea
        name={props.id}
        className={inputclass}
        {...(register ? register(props.id) : [])}
        {...props}
      />
      <ErrorMessage message={error} />
    </div>
  );
};

Textarea.displayName = "Textarea";

export default Textarea;
